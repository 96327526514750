import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../homepage.css';
import { ReactComponent as Background } from '../../../styles/images/storeFront.svg';
import { ReactComponent as Logo } from '../../../styles/logo/logo.svg';
import * as ReactDOMServer from 'react-dom/server';
import TouchableOpacity from '../../../components/touchableOpacity';
const HomePage1 = () => {
  const navigate = useNavigate();
  const goToMenu = () => {
    navigate('/menu');
  };

  const svgString = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Background />));
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '600px',
          backgroundImage: `url("data:image/svg+xml,${svgString}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}>
        <div
          style={{
            position: 'relative',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '600px'
          }}>
          <Logo className="companyLogo" />
          <TouchableOpacity className="touchableOpacity" onClick={goToMenu}>
            <Button className="firstPageMenuButton">Menu</Button>
          </TouchableOpacity>
        </div>
      </div>
    </>
  );
};

export default HomePage1;
