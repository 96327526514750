import '../homepage.css';
import Insta from '../../../styles/logo/insta-logo.png';
import Mail from '../../../styles/logo/mail-logo.png';
const HomePage5 = () => {
  const composeEmail = () => {
    window.open('mailto:info.huffbagerly@gmail.com');
  };
  const openInstagram = () => {
    window.open('https://www.instagram.com/huff.bagelry?igsh=MTMyZ3E3YWlndGc1ag==');
  };
  return (
    <>
      <div
        style={{
          backgroundColor: '#FFFBF5',
          justifyContent: 'center',
          width: '92%',
          padding: '1% 4%'
        }}>
        <h2 className="fifthPageTitle">Let&apos;s Connect</h2>
        <div className="fifthPageContainer">
          <div className="addressColumn">
            <h4 className="addressTitle">South Melbourne</h4>
            <p className="address">118 Bank St, South Melbourne VIC, 3205, Australia</p>
          </div>
          <div className="addressColumn">
            <h4 className="addressTitle">Bentleigh</h4>
            <p className="address">456 Centre Rd, Bentleigh VIC, 3204, Australia</p>
          </div>
          <div className="addressColumn">
            <h4 className="addressTitle">Carnegie</h4>
            <p className="address">112 Koornang Rd, Carnegie VIC, 3163, Australia</p>
          </div>
          <div className="addressColumn">
            <h4 className="addressTitle">Mentone</h4>
            <p className="address">13 Como Parade W, Mentone VIC, 3194, Australia </p>
          </div>
          <div className="addressColumn">
            <h4 className="addressTitle">Fitzroy</h4>
            <p className="address">238 Gertrude St, Fitzroy VIC 3065, Australia</p>
          </div>
        </div>
        <div className="pageContainer" style={{ margin: '5%', width: '90%' }}>
          <div className="pageColumn">
            <p className="fifthPageOpen">WE ARE OPEN 7 DAYS A WEEK </p>
          </div>
          <div className="logoColumn">
            <img className="redirectLogo" src={Insta} onClick={openInstagram}></img>
            <img className="redirectLogo" src={Mail} onClick={composeEmail}></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage5;
