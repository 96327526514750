import '../homepage.css';
const HomePage2 = () => {
  return (
    <>
      <div
        className="pageContainer"
        style={{ backgroundColor: '#FFFBF5', width: '92%', padding: '4%' }}>
        <div className="pageColumn">
          <p className="secondPageLeftText">
            Wide range of bagels, coffee & snacks. Something to satisfy every craving.
          </p>
        </div>
        <div className="pageColumn">
          <div className="secondPageRightText">
            <h4 className="pageHeader">Oven Fresh Bagels</h4>
            <p className="pageDescription">
              Indulge in our bagels crafted from scratch using premium ingredients.
            </p>
            <h4 className="pageHeader">Freshly Brewed Coffee</h4>
            <p className="pageDescription">
              Relish in our freshly roasted coffee brewed to perfection with top-quality beans.
            </p>
            <h4 className="pageHeader">Refreshing Drinks & Satisfying Snacks </h4>
            <p className="pageDescription">
              Dive into a world of flavors with our extensive selection of drinks and snacks.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage2;
