import '../homepage.css';
import { ReactComponent as Background } from '../../../styles/images/bagelsStore.svg';
import * as ReactDOMServer from 'react-dom/server';
const HomePage4 = () => {
  const svgString = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Background />));
  return (
    <>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '600px',
          backgroundImage: `url("data:image/svg+xml,${svgString}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}></div>
    </>
  );
};

export default HomePage4;
