import HomePage1 from './components/homePage1';
import HomePage2 from './components/homePage2';
import HomePage3 from './components/homePage3';
import HomePage4 from './components/homePage4';
import HomePage5 from './components/homePage5';

const Homepage = () => {
  return (
    <>
      <HomePage1 />
      <HomePage2 />
      <HomePage3 />
      <HomePage4 />
      <HomePage5 />
    </>
  );
};

export default Homepage;
