/* eslint-disable react/prop-types */
import React from 'react';

export default function TouchableOpacity({
  onClick,
  className,
  children,
  ...rest
}: TTouchableOpacityProps) {
  const [touched, touchedSet] = React.useState(false);

  return (
    <button
      className={className}
      style={{
        opacity: touched ? 0.5 : 1,
        transition: 'opacity 300ms ease',
        padding: 0
      }}
      onMouseDown={() => touchedSet(true)}
      onMouseUp={() => touchedSet(false)}
      onClick={onClick}
      {...rest}>
      {children}
    </button>
  );
}

type TTouchableOpacityProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
