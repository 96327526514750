import { useNavigate } from 'react-router-dom';
import './menu.css';
import data from './menuItems.json';
import { ReactComponent as Logo } from '../../styles/logo/dark-logo.svg';
import TouchableOpacity from '../../components/touchableOpacity';

const Menu = () => {
  const navigate = useNavigate();
  const goBack = () => {
    // navigate(-1);
    navigate('/');
  };

  const MenuSection = ({ section }: any) => {
    return (
      <>
        <h2 className="itemHeader">{section.header}</h2>
        {(section?.subHeader || section?.subHeaderItalic) && (
          <h4 className="itemSubHeader">
            {section.subHeader}
            <span className="italicText">{section.subHeaderItalic}</span>
          </h4>
        )}
        {section?.items ? (
          <>
            {section?.items?.map((item: any, index: number) => (
              <>
                <div className="itemHeaderBox">
                  <p key={index} className="itemTitle">
                    {item.header}
                  </p>
                  {item?.price && (
                    <>
                      <div className="dottedDataSeparator"></div>
                      <p className="price">{item.price}</p>
                    </>
                  )}
                </div>
                {(item?.subHeader || item?.subHeaderItalic) && (
                  <p key={index} className="itemSubTitle">
                    {item.subHeader}
                    <span className="italicText">{item.subHeaderItalic}</span>
                  </p>
                )}
                {item?.options && (
                  <>
                    {item.options.map((option: any, index: number) => (
                      <>
                        <p key={index} className="itemSubTitleDark">
                          {option.header}
                        </p>
                        <p key={index} className="itemSubTitle">
                          {option.subHeader}
                        </p>
                      </>
                    ))}
                  </>
                )}
              </>
            ))}
          </>
        ) : (
          <>
            <div className="innerContainer">
              <div className="innerColumn1">
                {section?.column1?.map((item: any, index: number) => (
                  <>
                    <p key={index} className="itemSubTitleDarkest">
                      {item.title}
                    </p>
                  </>
                ))}
              </div>
              <div className="innerColumn2">
                {section?.column2?.map((item: any, index: number) => (
                  <>
                    <p key={index} className="itemSubTitleDarkest">
                      {item.title}
                    </p>
                  </>
                ))}
              </div>
            </div>
            <div className="footer">
              <p className="itemSubTitle">{section.footer}</p>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="main">
        <div className="innerMenu">
          <TouchableOpacity className="touchableOpacity" onClick={goBack}>
            <Logo className="backLogo" />
          </TouchableOpacity>
          <div className="menuContainer">
            <div className="menuColumn">
              {data?.column1?.map((item: any, index: number) => (
                <>
                  <MenuSection key={index} section={item} />
                </>
              ))}
            </div>
            <div className="menuColumn">
              {data?.column2?.map((item: any, index: number) => (
                <>
                  <MenuSection key={index} section={item} />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
