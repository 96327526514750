import '../homepage.css';
import { Button } from 'react-bootstrap';
import { ReactComponent as Image } from '../../../styles/images/bagelStack.svg';
import TouchableOpacity from '../../../components/touchableOpacity';
import { useNavigate } from 'react-router-dom';
const HomePage3 = () => {
  const navigate = useNavigate();
  const goToMenu = () => {
    navigate('/menu');
  };
  return (
    <>
      <div className="thirdPageContainer" style={{ backgroundColor: '#FFF9ED' }}>
        <Image className="thirdPageImage" />
        <div className="thirdPageColumn">
          <div className="thirdPageText">
            <p className="thirdPageTitle">WHAT’S ON THE TABLE</p>
            <p className="thirdPageDescription">
              Experience the perfect blend of freshly brewed coffee, artisanal bagels, and a
              tantalizing array of snacks at Huff Bagelry. Our handcrafted treats, made with the
              finest ingredients, are served with a personal touch and professional expertise.
              Indulge in a flavorful journey with us.
            </p>
            <TouchableOpacity
              style={{ marginTop: '0.5rem', alignSelf: 'flex-start' }}
              className="touchableOpacity"
              onClick={goToMenu}>
              <Button className="thirdPageMenuButton">See Menu</Button>
            </TouchableOpacity>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage3;
